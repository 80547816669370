import React, { Fragment } from "react";
import { Alert, AlertTitle, Button, Divider, Typography, useTheme } from "@mui/material";
import { Authenticated, Title, useGetList, useStore } from "react-admin";
import { parseJwt } from "./auth/AuthUtils";
import { useSnackbar } from "notistack";
import config from "./config.json";

export const Dashboard = () => {

    const theme = useTheme();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();


    const DashboardContent = () => {
        const jwtPayload: any = parseJwt();
        const projects = jwtPayload.projects ? Object.keys(jwtPayload.projects) : [];

        if (projects.length === 0)
            return (
                <Fragment>
                    You don't have any projects assigned. Please contact Administrator.
                </Fragment>
            );

        return (
            <Fragment>
                <Title title="Dashboard" />
                <Typography variant="h6" p={2}>Welcome to HomeCloud</Typography>
            </Fragment>
        );
    };

    const AnnouncementPortal = () => {

        const [ selectedProject ] = useStore<string>("selected-project");

        const { data: announcements, total, isLoading, error, refetch } = useGetList(
            "announcement",
            {
                pagination: { page: 1, perPage: 25 },
                sort: { field: "recordId", order: "DESC" },
                filter: {},
                meta: {
                    projectUuid: selectedProject,
                },
            },
            {
                enabled: !! selectedProject,
            },
        );

        if (isLoading || ! announcements || announcements.length === 0) return;

        return (
            <Fragment>
                <Typography variant="h6" m={2}>Announcements</Typography>
                {announcements.map((announcement: Announcement) => (
                    <Alert severity="warning" key={announcement.recordId}>
                        <AlertTitle>By {announcement.createdBy}</AlertTitle>
                        {announcement.message}
                    </Alert>
                ))}
            </Fragment>
        );
    };

    const showAction = (snackbarId: string | number) => {


        return (
            <Fragment>
                <Button>123</Button>
                <Button onClick={() => closeSnackbar(snackbarId)}>123</Button>
            </Fragment>
        );
    };

    return (
        <Authenticated requireAuth>
            <DashboardContent />
            <AnnouncementPortal />

            {/*<Button onClick={() => enqueueSnackbar("\n" +*/}
            {/*    "                123321\n" +*/}
            {/*    "                123321\n" +*/}
            {/*    "                123321\n" +*/}
            {/*    "                123321\n" +*/}
            {/*    "                123321\n" +*/}
            {/*    "                123321\n" +*/}
            {/*    "                123321\n" +*/}
            {/*    "                123321\n" +*/}
            {/*    "                123321\n" +*/}
            {/*    "                123321", {*/}
            {/*    title: "Hello World",*/}
            {/*    variant: "info",*/}
            {/*    action: showAction,*/}
            {/*    persist: true,*/}
            {/*})}>*/}
            {/*    123321*/}
            {/*    123321*/}
            {/*    123321*/}
            {/*    123321*/}
            {/*    123321*/}
            {/*    123321*/}
            {/*    123321*/}
            {/*    123321*/}
            {/*    123321*/}
            {/*    123321*/}
            {/*</Button>*/}

            {/*<Button onClick={() => enqueueSnackbar("ERROR", {*/}
            {/*    variant: "error",*/}
            {/*    action: showAction,*/}
            {/*    persist: true,*/}
            {/*    title: ""*/}
            {/*})}>ERROR</Button>*/}

            {/*<Button onClick={() => enqueueSnackbar("WARNING", {*/}
            {/*    variant: "warning",*/}
            {/*    title: ""*/}
            {/*})}>WARNING</Button>*/}

            <Divider />
            <Typography>Current version: {config.VERSION}</Typography>
        </Authenticated>
    );
};
