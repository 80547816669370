import React from "react";
import {
    Datagrid,
    FunctionField,
    List,
    ReferenceField,
    TextField,
    useTranslate,
} from "react-admin";
import { Box, Button, Card, CardContent, CardHeader, Divider, Stack } from "@mui/material";
import CustomBreadcrumbs from "../../../component/CustomBreadcrumbs";
import CreateIconButton from "../../../component/CreateIconButton";
import CustomShowButton from "../../../component/CustomShowButton";
import CustomEditButton from "../../../component/CustomEditButton";
import CustomCloneButton from "../../../component/CustomCloneButton";
import CustomDeleteIconButtonV2 from "../../../component/CustomDeleteIconButtonV2";
import { useNavigate } from "react-router";

const RetailerMembershipList: React.FC = () => {

    const translate = useTranslate();
    const navigate = useNavigate();

    const defaultFilters = {};

    return (
        <List
            actions={false}
            exporter={false}
            sort={{ field: "recordId", order: "ASC" }}
            perPage={25}
            component="div"
            title="Retailer Membership Management"
            filterDefaultValues={defaultFilters}
            empty={false}
        >
            <Box>
                <CustomBreadcrumbs title="Retailer Membership" items={[]} />

                <Stack spacing={2} sx={{ p: 1 }}>
                    <Box>
                        <Button
                            variant="contained"
                            onClick={() => {
                                navigate("/retailer");
                            }}
                        >
                            Retailer Management
                        </Button>
                    </Box>
                </Stack>

                <Card>
                    <CardHeader title="Retailer Memberships" action={<CreateIconButton />} />
                    <Divider />
                    <CardContent>
                        <Datagrid bulkActionButtons={false}>
                            <FunctionField label="common.table.actions"
                                           render={() => (
                                               <Stack component="div" spacing={1} direction="row">
                                                   <CustomShowButton />
                                                   <CustomEditButton />
                                                   <CustomCloneButton
                                                       omitProps={[
                                                           "id", "recordId", "createdBy", "createdDate", "updatedBy", "updatedDate",
                                                       ]}
                                                   />
                                                   <CustomDeleteIconButtonV2 IconButtonProps={{
                                                       sx: {
                                                           "svg": {
                                                               fontSize: "16px",
                                                           },
                                                       },
                                                   }} />
                                               </Stack>
                                           )} />
                            <ReferenceField
                                sortable={false}
                                reference="retailer"
                                source="retailerId"
                            >
                                <TextField source="name" />
                            </ReferenceField>
                            <TextField source="name" />
                            <TextField source="description" />
                            <TextField source="recordId" label="#" />
                            <TextField source="seq" />
                        </Datagrid>
                    </CardContent>
                </Card>
            </Box>
        </List>
    );
};

export default RetailerMembershipList;
