import React, { Fragment, useCallback, useMemo } from "react";
import { Identifier } from "ra-core";
import CustomBreadcrumbs, { BreadcrumbItem } from "../../../component/CustomBreadcrumbs";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { Show, TextField, useShowContext } from "react-admin";
import Loading from "../../../component/common/Loading";
import { Box, Card, CardContent, CardHeader, Divider, Stack, Typography } from "@mui/material";
import CustomEditButtonV2 from "../../../component/CustomEditButtonV2";
import CustomDeleteIconButtonV2 from "../../../component/CustomDeleteIconButtonV2";
import moment from "moment";
import VoucherBarcodeDialog from "../../voucher/VoucherBarcodeDialog";

const RetailerMemberShipShow: React.FC<{ id?: Identifier }> = ({ id }) => {

    const [ urlParams ] = useSearchParams();
    const navigate = useNavigate();

    const retailerId = useMemo(() => {
        return urlParams.get("retailerId");
    }, [urlParams]);

    const getBreadcrumbItems = useCallback((): BreadcrumbItem[] => {
        console.log("Rendering");
        if (retailerId) {
            return [
                {
                    label: "Retailers",
                    resource: "retailer",
                    type: "list",
                },
                {
                    label: "Retailer",
                    resource: "retailer",
                    type: "show",
                    id: retailerId,
                },
                {
                    label: "Membership",
                    resource: "retailer/membership",
                    isCurrent: true,
                }
            ];
        } else {
            return [
                {
                    label: "Retailer Memberships",
                    resource: "retailer/membership",
                    type: "list",
                },
                {
                    label: "Retailer Membership",
                    resource: "retailer/membership",
                    isCurrent: true,
                },
            ];
        }
    }, [ retailerId ]);

    const RetailerMembershipContent: React.FC = () => {
        const {
            isFetching, // boolean that is true while the record is being fetched, and false once the record is fetched
            isLoading, // boolean that is true until the record is available for the first time
            record, // record fetched via dataProvider.getOne() based on the id from the location
            refetch, // callback to refetch the record via dataProvider.getOne()
        } = useShowContext<RetailerMembership>();

        const redirectTo = useMemo(() => {
            if (retailerId) {
                return `/retailer/${retailerId}`;
            }
            return "list";
        }, []);


        if (!record || isLoading || isFetching) {
            return <Loading />;
        }

        const CardHeaderAction = () => (
            <Stack direction="row" spacing={1}>
                <CustomEditButtonV2 />
                <CustomDeleteIconButtonV2 redirectTo={redirectTo} />
            </Stack>
        );

        return (
            <Box sx={{ width: '100%' }}>
                <Card>
                    <CardHeader title={`Retailer Membership #${record.recordId}`} action={<CardHeaderAction />} />
                    <CardContent sx={{ width: '100%' }}>
                        {record.barcodeText && record.barcodeType && (
                            <VoucherBarcodeDialog barcodeText={record.barcodeText} barcodeType={record.barcodeType}
                                                  small />
                        )}
                        <Typography>Record ID: {record.recordId}</Typography>
                        <Typography>Name: {record.name}</Typography>
                        <Typography>Description: {record.description}</Typography>
                        <Typography>Barcode: {record.recordId}</Typography>
                        <Typography>Retailer: {record.retailerId}</Typography>
                        <Typography>SEQ: {record.seq}</Typography>

                        <TextField source="name" sx={{ fontSize: "inherit" }} />


                        <Divider />

                        <Typography>Created By: {record.createdBy}</Typography>
                        <Typography>Created Date: {moment(record.createdDate).format("LLL")}</Typography>
                        <Typography>Updated By: {record.updatedBy}</Typography>
                        <Typography>Created Date: {moment(record.updatedDate).format("LLL")}</Typography>

                    </CardContent>
                </Card>
            </Box>
        );
    }


    return (
        <Show actions={false}
              component="div"
              title="Retailer Membership Management"
              resource="retailer/membership"
              id={id}
              queryOptions={{
                  onError: () => {
                      console.log("Error!!!!")
                      navigate(-1);
                  }
              }}

        >
            <CustomBreadcrumbs title="Retailer Membership" items={getBreadcrumbItems()} />
            <RetailerMembershipContent />
        </Show>
    );
};

export default RetailerMemberShipShow;
