import { DataProvider, fetchUtils } from "react-admin";
import { sharedDataProvider } from "../../../hooks/sharedDataProvider";
import axios from "axios";
import config from "../../../config.json";
import { convertToFilter } from "../../../hooks/DataProviderUtils";

export interface VoucherDataProvider extends DataProvider {
    fetchVoucherConsumptionDetails: (resource: string, options: FetchVoucherConsumptionDetailsOptions) => Promise<VoucherConsumptionDetails[]>;
}

export const voucherDataProvider = (projectUuid: string, baseUrl: string = `${config.API_BASE_URL}`): VoucherDataProvider => {
    return {
        ...sharedDataProvider(projectUuid, baseUrl),
        fetchVoucherConsumptionDetails: async (_, options) => {
            const { userUuid, projectUuid, form } = options;
            return axios.get<VoucherConsumptionDetails[]>(`${config.API_BASE_URL}/project/${projectUuid}/user/${userUuid}/voucher/consumption/details?${convertToFilter(form)}`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem("auth")}`
                }
            })
                .then((result) => {
                    return result.data;
                })
                .catch((error) => {
                    throw new Error(`Error during fetch voucher consumption details`, error);
                });
        }
    }
}
