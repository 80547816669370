import React from "react";
import { Resource } from "react-admin";
import RetailerMembershipList from "./RetailerMembershipList";
import RetailerMemberShipShow from "./RetailerMemberShipShow";
import RetailerMembershipCreate from "./RetailerMembershipCreate";
import RetailerMembershipEdit from "./RetailerMembershipEdit";

const RetailerMembership = (
    <Resource key="retailer/membership"
              name="retailer/membership"
              create={RetailerMembershipCreate}
              edit={RetailerMembershipEdit}
              list={RetailerMembershipList}
              show={RetailerMemberShipShow}
    />
);

export default RetailerMembership;