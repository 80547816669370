import React from "react";
import { IconButtonProps, Stack, Theme, useMediaQuery } from "@mui/material";
import CustomEditButtonV2 from "./CustomEditButtonV2";
import SmallFloatingButtonGroup from "./SmallFloatingButtonGroup";
import CustomDeleteIconButtonV2 from "./CustomDeleteIconButtonV2";

const GroupedButton: React.FC<GroupedButtonProps> =
    (props: any) => {

    const {
        showCreate = false,
        showView = false,
        showEdit = false,
        showDelete = false,
        disableCreate = false,
        disableView = false,
        disableEdit = false,
        disableDelete = false,
        ...rest
    } = props;

        const isFloating = useMediaQuery((theme: Theme) =>
            theme.breakpoints.down('md')
        );

        const FloatingButtons = () => (
            <SmallFloatingButtonGroup />
        );

        const StackButtons: React.FC<{
            showCreate?: boolean
            showView?: boolean;
            showEdit?: boolean;
            showDelete?: boolean;
            disableCreate?: boolean;
            disableView?: boolean;
            disableEdit?: boolean;
            disableDelete?: boolean;
        }> = ({
                  showCreate = false,
                  showView = false,
                  showEdit = false,
                  showDelete = false,
                  disableCreate = false,
                  disableView = false,
                  disableEdit = false,
                  disableDelete = false,

              }) => (
            <Stack direction="row" spacing={1}>
                <CustomEditButtonV2 IconButtonProps={{ size: "small" }} {...rest} />
                <CustomDeleteIconButtonV2 IconButtonProps={{ size: "small" }} {...rest}   />
            </Stack>
        );

        return (
            isFloating ? <FloatingButtons /> : <StackButtons />
        );
    };

interface Props {

}

type GroupedButtonProps = Props & IconButtonProps & {
    showCreate?: boolean
    showView?: boolean;
    showEdit?: boolean;
    showClone?: boolean;
    showDelete?: boolean;
    disableCreate?: boolean;
    disableView?: boolean;
    disableEdit?: boolean;
    disabledClone?: boolean;
    disableDelete?: boolean;
};


export default GroupedButton;
