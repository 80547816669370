import { BarcodeFormat } from "@zxing/library";

/**
 * @param barcodeFormat
 */
export const convertToFormatName = (barcodeFormat: BarcodeFormat) => {
    switch (barcodeFormat) {
        case BarcodeFormat.AZTEC:
            return "AZTEC";
        case BarcodeFormat.CODE_128:
            return "CODE_128";
        case BarcodeFormat.CODE_93:
            return "CODE_93";
        case BarcodeFormat.CODE_39:
            return "CODE_39";
        case BarcodeFormat.EAN_13:
            return "EAN_13";
        case BarcodeFormat.EAN_8:
            return "EAN_8";
        case BarcodeFormat.QR_CODE:
            return "QR_CODE";
        case BarcodeFormat.CODABAR:
            return "CODABAR";
        case BarcodeFormat.UPC_A:
            return "UPC_A";
        case BarcodeFormat.UPC_E:
            return "UPC_E";
        case BarcodeFormat.UPC_EAN_EXTENSION:
            return "UPC_EAN_EXTENSION";
        case BarcodeFormat.PDF_417:
            return "PDF_417";
        case BarcodeFormat.RSS_14:
            return "RSS_14";
        case BarcodeFormat.RSS_EXPANDED:
            return "RSS_EXPANDED";
        case BarcodeFormat.ITF:
            return "ITF";
        case BarcodeFormat.DATA_MATRIX:
            return "DATA_MATRIX";
        case BarcodeFormat.MAXICODE:
            return "MAXICODE";
        default:
            return "ERROR";
    }
}
export const convertToFormat = (format: string): BarcodeFormat => {
    switch (format) {
        case "AZTEC":
            return BarcodeFormat.AZTEC;
        case "CODE_128":
            return BarcodeFormat.CODE_128;
        case "CODE_93":
            return BarcodeFormat.CODE_93;
        case "CODE_39":
            return BarcodeFormat.CODE_39;
        case "EAN_13":
            return BarcodeFormat.EAN_13;
        case "EAN_8":
            return BarcodeFormat.EAN_8;
        case "QR_CODE":
            return BarcodeFormat.QR_CODE;
        case "CODABAR":
            return BarcodeFormat.CODABAR;
        case "UPC_A":
            return BarcodeFormat.UPC_A;
        case "UPC_E":
            return BarcodeFormat.UPC_E;
        case "UPC_EAN_EXTENSION":
            return BarcodeFormat.UPC_EAN_EXTENSION;
        case "PDF_417":
            return BarcodeFormat.PDF_417;
        case "RSS_14":
            return BarcodeFormat.RSS_14;
        case "RSS_EXPANDED":
            return BarcodeFormat.RSS_EXPANDED;
        case "ITF":
            return BarcodeFormat.ITF;
        case "DATA_MATRIX":
            return BarcodeFormat.DATA_MATRIX;
        case "MAXICODE":
            return BarcodeFormat.MAXICODE;
        default:
            throw new Error(`Unknown barcode type : ${format}`);
    }
}