import { getUserUuid } from "../../../auth/AuthUtils";
import { useQuery } from "@tanstack/react-query";
import getTags from "./getTags";
import { useStore } from "react-admin";

const useGetTags = () => {
    const [ projectUuid ] = useStore<string>("selected-project");
    const userUuid = getUserUuid() || "";

    return useQuery({
        queryKey: [ 'getTags', projectUuid ],
        queryFn: () => getTags(projectUuid, userUuid),
        enabled: projectUuid.length > 0 && userUuid.length > 0,
    });
};

export default useGetTags;
