import { DataProvider } from "react-admin";
import { axiosRequest, convertToGetListParams } from "../../hooks/DataProviderUtils";
import { getUserUuid } from "../../auth/AuthUtils";

export const adminProjectRoleDataProvider = (baseUrl: string): DataProvider => {

    return {
        create: (_resource, params) => {
            const { meta } = params;
            return axiosRequest({
                fetchType: "CREATE",
                url: `${baseUrl}/user/${getUserUuid()}/project/${meta.projectId}/role/create`,
                body: JSON.stringify(params),
            });
        },
        delete: (_resource, params) => {
            // FIXME: fixed project????
            return axiosRequest({
                fetchType: "DELETE",
                url: `${baseUrl}/user/${getUserUuid()}/project/eece2a7c-f0a5-463d-bcea-13d50e5eb997/role/${params.id}/delete`,
            });
        },
        deleteMany: (_resource, _params) => Promise.reject("Delete many is not supported"),
        getList: (_resource, params) => {
            const { meta } = params;
            return axiosRequest({
                fetchType: "GET_LIST",
                url: `${baseUrl}/user/${getUserUuid()}/project/${meta.projectUuid}/role/list${convertToGetListParams(params)}`,
            });
        },
        getMany: (_resource, params) => {
            const { ids, meta } = params;
            const mixedParam = ids.reduce((p, id) => {
                return `${p}&recordId=${id}`;
            }, "");
            return axiosRequest({
                fetchType: "GET_MANY",
                url: `${baseUrl}/user/${getUserUuid()}/project/${meta.projectId}/role?${mixedParam}`,
            });
        },
        getManyReference: (_resource, params) => {
            const { id, target } = params;
            return axiosRequest({
                fetchType: "GET_MANY_REFERENCE",
                url: `${baseUrl}/user/${getUserUuid()}/project/${id}/role/list${convertToGetListParams(params)}&${target}=${id}`,
            });
        },
        getOne: (_resource, params) => {
            const { id, meta } = params;
            return axiosRequest({
                fetchType: "GET_ONE",
                url: `${baseUrl}/user/${getUserUuid()}/project/${meta.projectId}/role/${id}`,
            });
        },
        update: (_resource, params) => {
            const { data, meta } = params;
            return axiosRequest({
                fetchType: "UPDATE",
                url: `${baseUrl}/user/${getUserUuid()}/project/${meta.projectId}/role/${params.id}/update`,
                body: JSON.stringify(data),
            });
        },
        updateMany: (_resource, _params) => Promise.reject("Not supported"),
    };
};
