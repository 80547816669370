import React, { useState } from "react";
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Paper,
    SxProps
} from "@mui/material";
import { Title } from "react-admin";
import { BarcodeFormat } from "@zxing/library";
import Grid from "@mui/material/Unstable_Grid2";
import CloseIcon from "@mui/icons-material/Close";
import BarcodeV2 from "../../component/common/BarcodeV2";
import ProgressiveImg from "../../component/ProgressiveImg";
import { colors } from "../../theme/ulTheme";

const StoreMembershipIndex = () => {

    type DialogInfo = {
        title: string,
        barcodeText: string,
        barcodeFormat: BarcodeFormat,
    }

    const [ current, setCurrent ] = useState("");
    const [ selectedDialogInfo, setSelectedDialogInfo ] = useState<DialogInfo>();

    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpended: boolean) => {
        setCurrent(isExpended ? panel : "");
    }

    const paperSx: SxProps = {
        minHeight: "150px",
        height: "100%",
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
        backgroundColor: colors.green["300"],
        "&:hover": {
            cursor: "pointer",
            backgroundColor: colors.green["500"],
        },
    };

    const openDialog = (title: string, barcodeText: string, barcodeFormat: BarcodeFormat) => {
        setSelectedDialogInfo({ title, barcodeText, barcodeFormat });
    }

    const closeDialog = () => {
        setSelectedDialogInfo(undefined);
    }

    const SimpleDialog: React.FC<{ dialogInfo?: DialogInfo, closeDialog: () => void }> =
        ({
             dialogInfo,
             closeDialog
        }) => {
            if (! dialogInfo) return null;
            return (
                <Dialog open={Boolean(dialogInfo)} onClose={closeDialog}>
                    <DialogTitle>
                        {dialogInfo.title}
                    </DialogTitle>
                    <IconButton
                        color="inherit"
                        onClick={closeDialog}
                        aria-label="close"
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <DialogContent>
                        <BarcodeV2 text={dialogInfo.barcodeText} format={dialogInfo.barcodeFormat} sx={{ width: "250px" }}/>
                    </DialogContent>
                </Dialog>
            )
        };

    return (
        <Box sx={{ pt: 5 }}>
            <Title title="Store Memberships" />
            <Grid container m={2} spacing={2} sx={{ textAlign: "center" }}>
                <Grid xs={12} md={3}>
                    <Paper component="div" sx={paperSx}
                           onClick={() => openDialog("Marks & Spencer", "982601331059563198", BarcodeFormat.CODE_128)}>
                        <ProgressiveImg src="https://assets.6uohk.com/logo/ms.svg" width="150" alt="M&S" />
                    </Paper>
                </Grid>
                <Grid xs={12} md={3}>
                    <Paper component="div" sx={paperSx}
                           onClick={() => openDialog("Morrisons", "9826135802502985613", BarcodeFormat.CODE_128)}>
                        <ProgressiveImg src="https://assets.6uohk.com/logo/morrisons.svg" width="150" alt="Tesco" />
                    </Paper>
                </Grid>
                <Grid xs={12} md={3}>
                    <Paper component="div" sx={paperSx}
                           onClick={() => openDialog("Tesco", "9794027731348681:39", BarcodeFormat.AZTEC)}>
                        <ProgressiveImg src="https://assets.6uohk.com/logo/tesco.svg" width="150" alt="Tesco" />
                    </Paper>
                </Grid>
                <Grid xs={12} md={3}>
                    <Paper component="div" sx={paperSx}
                           onClick={() => openDialog("Sainsburys (Necter)", "29964519478012", BarcodeFormat.CODE_128)}>
                        <ProgressiveImg src="https://assets.6uohk.com/logo/sainsburys.svg" width="150" alt="Tesco" />
                    </Paper>
                </Grid>
            </Grid>
            <SimpleDialog closeDialog={closeDialog} dialogInfo={selectedDialogInfo} />

        </Box>
    )
};

export default StoreMembershipIndex;
