import { parseJwt } from "./auth/AuthUtils";
import { userAuthorize } from "./pages/auth/dataHookTest";
import { Mutex } from "async-mutex";
import config from "./config.json";

const refreshMutex = new Mutex();

export const refreshAuth = async (): Promise<void> => {
    // RefreshAuth may run multiple time
    if (refreshMutex.isLocked())
        return refreshMutex.waitForUnlock().then(
            () => {
                return refreshAuth();
            }
        );
    refreshMutex.acquire()
        .then(() => {
            return refresh();
        });
};

const logoutAndCleanLocalStorage = () => {
    localStorage.removeItem("auth");
    localStorage.removeItem("token");
    window.location.href = `${config.BASE_URL}/login`;
}

const refresh = async (): Promise<void> => {
    if (! localStorage.getItem("auth")) {
        refreshMutex.cancel();
        logoutAndCleanLocalStorage();
        return Promise.resolve();
    }
    const jwtPayload: any = parseJwt();

    const exp: number = jwtPayload.exp as number;
    const cur: number = Date.now() / 1000 | 0 as number;


    if (cur > exp) {
        refreshMutex.cancel();
        logoutAndCleanLocalStorage();
        return Promise.resolve();
    } else {
        const lastUpdatedDate = +(localStorage.getItem("tu") || 0);
        if (Date.now() > lastUpdatedDate + (1000 * 60 * 60)) {
            userAuthorize().then((result) => {
                    localStorage.setItem("tu", Date.now().toString());
                    localStorage.setItem('auth', result.token);
                refreshMutex.release();
            }).catch(() => {
                refreshMutex.release();
                logoutAndCleanLocalStorage();
                return Promise.resolve();
            });
        } else {
            refreshMutex.release();
        }
    }

    return Promise.resolve();
}
