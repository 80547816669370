import React, {useEffect, useMemo, useState} from "react";
import {
    Box,
    Card, CardContent,
    CardHeader,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Typography,
} from "@mui/material";
import {Title, useGetList, useStore} from "react-admin";
import {BarcodeFormat} from "@zxing/library";
import Grid from "@mui/material/Unstable_Grid2";
import CloseIcon from "@mui/icons-material/Close";
import { colors } from "../../../theme/ulTheme";
import BarcodeV2 from "../../../component/common/BarcodeV2";
import { convertToFormat } from "../../../helper/BarcodeTypeUtils";
import ProgressiveImg from "../../../component/ProgressiveImg";

const RetailerMembershipSelection = () => {

    type IntegratedRetailerMembership = {
        recordId: number;
        membershipName: string;
        retailerName: string;
        imagePath?: string;
        barcodeText: string;
        barcodeType: string;
    };

    const [integratedMemberships, setIntegratedMemberships] = useStore<IntegratedRetailerMembership[]>("retailer-memberships", []);

    const {
        data: retailer,
        isSuccess: isRetailerSuccess,
    } = useGetList<Retailer>("retailer", {
        pagination: {
            page: 1,
            perPage: 500,
        }
    });

    const {
        data: retailerMembership,
        isSuccess: isRetailerMembershipSuccess,
    } = useGetList<RetailerMembership>("retailer/membership", {
        pagination: {
            page: 1,
            perPage: 500,
        },
        sort: {
            field: "seq",
            order: "ASC"
        }
    });

    const retailerMapping: { [id: number]: Retailer } = useMemo(() => {
        if (!!retailer && isRetailerSuccess) {
            console.log("render render");
            return retailer.reduce((prev: { [id: number]: Retailer }, curr) => {
                prev[curr.recordId] = curr;
                return prev;
            }, {});
        }
        return {};
    }, [isRetailerSuccess, retailer]);

    useEffect(() => {
        if (Object.keys(retailerMapping).length > 0 && !!retailerMembership && isRetailerMembershipSuccess) {
            console.log("render render");
            const temp = retailerMembership.reduce((prev: IntegratedRetailerMembership[], curr) => {
                prev.push({
                    recordId: curr.recordId,
                    membershipName: curr.name,
                    retailerName: retailerMapping[curr.retailerId].name,
                    imagePath: retailerMapping[curr.retailerId].imagePath,
                    barcodeText: curr.barcodeText,
                    barcodeType: curr.barcodeType,
                });
                return prev;
            }, []);
            setIntegratedMemberships(temp);
        }
    }, [retailerMapping, retailerMembership, isRetailerMembershipSuccess, setIntegratedMemberships]);

    type DialogInfo = {
        title: string,
        barcodeText: string,
        barcodeFormat: BarcodeFormat,
    }

    const [selectedDialogInfo, setSelectedDialogInfo] = useState<DialogInfo>();

    const openDialog = (title: string, barcodeText: string, barcodeFormat: BarcodeFormat) => {
        setSelectedDialogInfo({title, barcodeText, barcodeFormat});
    }

    const closeDialog = () => {
        setSelectedDialogInfo(undefined);
    }

    const SimpleDialog: React.FC<{ dialogInfo?: DialogInfo, closeDialog: () => void }> =
        ({
             dialogInfo,
             closeDialog
         }) => {
            if (!dialogInfo) return null;
            return (
                <Dialog open={Boolean(dialogInfo)} onClose={closeDialog} data-testid="simple-dialog">
                    <DialogTitle>
                        {dialogInfo.title}
                    </DialogTitle>
                    <IconButton
                        color="inherit"
                        onClick={closeDialog}
                        aria-label="close"
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon/>
                    </IconButton>
                    <DialogContent>
                        <BarcodeV2 text={dialogInfo.barcodeText} format={dialogInfo.barcodeFormat}
                                   sx={{width: "250px"}}/>
                    </DialogContent>
                </Dialog>
            )
        };

    return (
        <Box sx={{pt: 5}}>
            <Title title="Store Memberships (v2)"/>
            <Grid container m={2} spacing={2} sx={{textAlign: "center"}}>
                {integratedMemberships.map(membership => {
                    return (
                        <Grid xs={12} md={3} key={`retailer-membership-grid-${membership.recordId}`}>
                            <Card
                                sx={{
                                    minHeight: "150px",
                                    height: "100%",
                                    color: colors.black,
                                    backgroundColor: colors.green["300"],
                                    "&:hover": {
                                        cursor: "pointer",
                                        backgroundColor: colors.green["500"],
                                    },
                                }}
                                data-testid={`retailer-membership-card-${membership.recordId}`}
                                onClick={() => openDialog(membership.membershipName, membership.barcodeText, convertToFormat(membership.barcodeType))}
                            >
                                <CardHeader title={<Typography sx={{ fontSize: 14}}>{membership.membershipName}</Typography>} />
                                <Divider/>
                                <CardContent
                                    sx={{
                                        height: "70%",
                                        justifyContent: "center",
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    {membership.imagePath ?
                                        <ProgressiveImg src={membership.imagePath} width="150"
                                                        alt={membership.membershipName}/> :
                                        <Box>
                                            {membership.retailerName}
                                        </Box>
                                    }
                                </CardContent>
                            </Card>
                        </Grid>
                    )
                })}
            </Grid>
            <SimpleDialog closeDialog={closeDialog} dialogInfo={selectedDialogInfo}/>

        </Box>
    )
};

export default RetailerMembershipSelection;
