import config from "./../../config.json";
import axios from "axios";

const uploadImage = (productId: string, file: Blob) => {
    const project = localStorage.getItem('project');
    const formData: FormData = new FormData();

    formData.append("file", file);

    return axios.post(`${config.API_BASE_URL}/project/${project}/product/${productId}/image/upload`, formData, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('auth')}`,
            "Content-Type": "multipart/form-data",
        },
    });
};

export {
    uploadImage,
};
