import React from 'react';
import config from "./../../config.json";
import { MenuList } from "@mui/material";
import DashboardIcon from '@mui/icons-material/Dashboard';
import PaymentsIcon from '@mui/icons-material/Payments';
import AppMenuItem from './AppMenuItem';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import StoreIcon from '@mui/icons-material/Store';
import BoltOutlinedIcon from '@mui/icons-material/BoltOutlined';
import { Description, Campaign } from '@mui/icons-material';
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';

export const adminAppMenuItems: ApplicationMenuItem[] = [
    {
        name: 'Dashboard',
        link: '/admin/',
        Icon: DashboardIcon,
    },
    {
        name: "Back To Front",
        link: "/",
        Icon: PaymentsIcon,
        selectable: false,
    },
    {
        name: "Project Management",
        link: "/admin/project",
        Icon: Campaign,
    },
    {
        name: "User Management",
        link: "/admin/user",
        Icon: LibraryBooksIcon,
    },
];

export const appMenuItems: ApplicationMenuItem[] = [
    {
        name: 'Dashboard',
        link: '/',
        Icon: DashboardIcon,
    },
    {
        name: "Super Administration",
        link: "/admin",
        Icon: PaymentsIcon,
        superAdminRequired: true,
        selectable: false,
    },
    {
        name: "Announcement",
        link: "/announcement",
        Icon: Campaign,
        permissionRequired: config.ROLES.ROLE_PROJECT_ADMIN,
    },
    {
        name: "Shop Membership",
        link: "/storeMembership",
        Icon: LibraryBooksIcon,
        permissionRequired: config.ROLES.ROLE_PROJECT_VOUCHER_MANAGEMENT,
    },
    {
        name: "Shop Membership v2",
        link: "/other/retailer/membership/selection",
        Icon: LibraryBooksIcon,
        permissionRequired: config.ROLES.ROLE_PROJECT_VOUCHER_MANAGEMENT,
    },
    {
        name: "Voucher",
        link: "/voucher",
        Icon: PaymentsIcon,
        permissionRequired: config.ROLES.ROLE_PROJECT_VOUCHER_MANAGEMENT,
    },
    {
        name: "Retailer",
        link: "/retailer",
        Icon: StoreIcon,
        permissionRequired: config.ROLES.ROLE_PROJECT_VOUCHER_MANAGEMENT,
    },
    {
        name: "Product",
        link: "/product",
        Icon: LibraryBooksIcon,
        permissionRequired: config.ROLES.ROLE_PROJECT_STORE_MANAGEMENT,
    },
    {
        name: "Product Group",
        link: "/productGroup",
        Icon: LibraryBooksIcon,
        permissionRequired: config.ROLES.ROLE_PROJECT_STORE_MANAGEMENT,
    },
    {
        name: "Shop",
        link: "/shop",
        Icon: StoreIcon,
        permissionRequired: config.ROLES.ROLE_PROJECT_STORE_MANAGEMENT,
    },
    {
        name: "Branch",
        link: "/branch",
        Icon: StoreIcon,
        permissionRequired: config.ROLES.ROLE_PROJECT_STORE_MANAGEMENT,
    },
    {
        name: "Brand",
        link: "/brand",
        Icon: LibraryBooksIcon,
        permissionRequired: config.ROLES.ROLE_PROJECT_STORE_MANAGEMENT,
    },
    {
        name: "Quantity Unit",
        link: "/quantityUnit",
        Icon: LibraryBooksIcon,
        permissionRequired: config.ROLES.ROLE_PROJECT_STORE_MANAGEMENT,
    },
    {
        name: "Stock Location",
        link: "/stock/location",
        Icon: LibraryBooksIcon,
        permissionRequired: config.ROLES.ROLE_PROJECT_STORE_MANAGEMENT,
    },
    {
        name: "Energy (v4)",
        Icon: BoltOutlinedIcon,
        items: [
            {
                name: "Energy - Index",
                link: "/energy/octopus/v4/index",
                Icon: BoltOutlinedIcon,
                permissionRequired: config.ROLES.ROLE_PROJECT_ENERGY,
            },
            {
                name: "Energy - Tariff",
                link: "/energy/octopus/v4/tariff",
                Icon: BoltOutlinedIcon,
                permissionRequired: config.ROLES.ROLE_PROJECT_ENERGY,
            },
            {
                name: "Energy - Consumption",
                link: "/energy/octopus/v4/consumption",
                Icon: BoltOutlinedIcon,
                permissionRequired: config.ROLES.ROLE_PROJECT_ENERGY,
            },
            {
                name: "Energy - Configuration",
                link: "/energy/octopus/v4/token/update",
                Icon: BoltOutlinedIcon,
                permissionRequired: config.ROLES.ROLE_PROJECT_ENERGY,
            },
        ],
        permissionRequired: config.ROLES.ROLE_PROJECT_ENERGY,
    },
    {
        name: "BNO Calculator v1",
        link: "/bno/calculation",
        Icon: CalculateOutlinedIcon,
        permissionRequired: config.ROLES.ROLE_BNO_CALCULATOR,
    },
    {
        name: "Test 2",
        link: "/voucherNew",
        Icon: LibraryBooksIcon,
        permissionRequired: "ROLE_PROJECT_ADMIN",
    },
    {
        name: "Equipments",
        link: "/equipment",
        Icon: PaymentsIcon,
        permissionRequired: config.ROLES.ROLE_PROJECT_EQUIPMENT_WRITE,
    },
    {
        name: "Documents",
        link: "/document/explorer",
        Icon: Description,
        permissionRequired: config.ROLES.ROLE_PROJECT_DOCUMENT_MANAGEMENT,
    }
];

const AppMenu: React.FC<{
    menuItems: ApplicationMenuItem[],
}> = ({ menuItems }) => {

    return (
        <MenuList component="nav" disablePadding>
            {menuItems.map((item, index) => {
                return (
                    <AppMenuItem item={item} key={index} />
                )
            })}
        </MenuList>
    )
}

export default AppMenu;
