import React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Edit } from "react-admin";
import { useNavigate } from "react-router";
import CustomBreadcrumbs, { BreadcrumbItem } from "../../../component/CustomBreadcrumbs";
import RetailerMembershipEditForm from "./form/RetailerMembershipEditForm";

const RetailerEdit: React.FC = () => {

    const { id } = useParams();
    const navigate = useNavigate();

    const breadcrumbItems: BreadcrumbItem[] = [
        {
            label: "Retailers",
            resource: "retailer",
            type: "list",
        },
        {
            label: "Retailer",
            resource: "retailer",
            type: "show",
            id: id,
        },
        {
            label: "Edit",
            isCurrent: true,
        },
    ];

    return (
        <Edit actions={false}
              component="div"
              mutationMode="pessimistic"
              title="Retailer Management"
              resource="retailer/membership"
              redirect={false}
              mutationOptions={{
                  onSuccess: (data: Retailer, _variables, _context) => {
                      // replace path -> if user go back previous page -> will go to list / the second previous instead of form page
                      navigate(`/retailer/membership/${data.recordId}/show`, {
                          replace: true,
                      });
                  },
              }}
        >
            <CustomBreadcrumbs title="Edit Retailer" items={breadcrumbItems} />
            <RetailerMembershipEditForm type="edit" />
        </Edit>
    );

};

export default RetailerEdit;
