import { DataProvider } from "react-admin";
import { axiosRequest, convertToGetListParams } from "../../hooks/DataProviderUtils";

export const sharedAdminDataProvider = (baseUrl: string): DataProvider => {

    return {
        create: (resource, params) => {
            return axiosRequest({
                fetchType: "CREATE",
                url: `${baseUrl}/${resource}/create`,
                body: JSON.stringify(params.data),
            });
        },
        delete: (resource, params) => {
            return axiosRequest({
                fetchType: "DELETE",
                url: `${baseUrl}/${resource}/delete/${params.id}`,
            });
        },
        deleteMany: (_resource, _params) => Promise.reject("Delete many is not supported"),
        getList: (resource, params) => {
            return axiosRequest({
                fetchType: "GET_LIST",
                url: `${baseUrl}/${resource}/list${convertToGetListParams(params)}`,
            });
        },
        getMany: (resource, params) => {
            const { ids } = params;

            const mixedParam = ids.reduce((p, id) => {
                return `${p}&recordId=${id}`;
            }, "");

            return axiosRequest({
                fetchType: "GET_MANY",
                url: `${baseUrl}/${resource}?${mixedParam}`,
            });
        },
        getManyReference: (resource, params) => {
            const { id, target } = params;
            return axiosRequest({
                fetchType: "GET_MANY_REFERENCE",
                url: `${baseUrl}/${resource}/list${convertToGetListParams(params)}&${target}=${id}`,
            });
        },
        getOne: (resource, params) => {
            const { id } = params;
            return axiosRequest({
                fetchType: "GET_ONE",
                url: `${baseUrl}/${resource}/${id}`,
            });
        },
        update: (resource, params) => {
            const { id, data } = params;
            return axiosRequest({
                fetchType: "UPDATE",
                url: `${baseUrl}/${resource}/${id}/update`,
                body: JSON.stringify(data),
            });
        },
        updateMany: (resource, params) => Promise.reject("Not supported"),
    } as DataProvider;
};
