import { AuthProvider } from "react-admin";
import { getSelectedProject, parseJwt } from "./auth/AuthUtils";
import { setUserUuid } from "./support/googleAnalytics";

export const authProvider: AuthProvider = {
        // called when the user attempts to log in
        // Use built-in function instead.
        login: ({ username, password }) => {
            return Promise.reject();
        },
        // called when the user clicks on the logout button
        logout: () => {
            localStorage.removeItem("auth");
            setUserUuid();
            return Promise.resolve();
        },
        // called when the API returns an error
        checkError: (error) => {
            const { status } = error;
            console.log(error);
            if (status === 401 || status === 403) {

                localStorage.removeItem("username");
                return Promise.reject();
            }
            return Promise.resolve();
        },
        // called when the user navigates to a new location, to check for authentication
        checkAuth: () => {
            if (! localStorage.getItem("auth"))
                return Promise.reject({ message: 'login.required' });

            const jwtPayload: any = parseJwt();
            const exp: number = jwtPayload.exp as number;
            const cur: number = Date.now() / 1000 | 0 as number;

            if (cur > exp)
                return Promise.reject({ message: 'Expired! Please login again!' });
            return Promise.resolve();
        },
        getIdentity: () => {
            const jwtPayload: JwtPayload = parseJwt();
            if (! jwtPayload) {
                localStorage.removeItem("auth");
                // localStorage.removeItem("RaStore.selected-project");
                return Promise.reject();
            }

            return Promise.resolve({
                    id: jwtPayload.userId,
                    fullName: jwtPayload.userName,
                    projectSelected: getSelectedProject(),
                    authenticated: true,
            });
        },
        // called when the user navigates to a new location, to check for permissions / roles
        getPermissions: () => {
            const jwtPayload: JwtPayload | null = parseJwt();
            if (! jwtPayload)
                return Promise.resolve([]);
            return Promise.resolve(jwtPayload.features);
        },
    }
;