import React from "react";
import {
    Datagrid, FunctionField,
    NumberField,
    Pagination,
    ReferenceManyField,
    RichTextField,
    Show, TextField,
    useShowContext,
} from "react-admin";
import CustomBreadcrumbs, { BreadcrumbItem } from "../../component/CustomBreadcrumbs";
import { Box, Card, CardContent, CardHeader, CircularProgress, Divider, Fade, Grid, Stack } from "@mui/material";
import GroupedButton from "../../component/GroupedButton";
import { Identifier } from "ra-core";
import { useNavigate } from "react-router";
import CustomShowButton from "../../component/CustomShowButton";
import CustomEditButton from "../../component/CustomEditButton";
import CustomCloneButton from "../../component/CustomCloneButton";
import CustomDeleteIconButtonV2 from "../../component/CustomDeleteIconButtonV2";

const RetailerShow: React.FC<{ id?: Identifier }> = ({ id }) => {

    const breadcrumbItems: BreadcrumbItem[] = [
        {
            label: 'Retailers',
            resource: 'retailer',
            type: 'list',
        },
        {
            label: 'Retailer',
            resource: 'retailer',
            isCurrent: true,
        },
    ];

    const RetailerShowContent: React.FC = () => {

        const { record, isLoading } = useShowContext<Retailer>();
        const navigate = useNavigate();

        if (isLoading)
            return <CircularProgress />;

        if (! record)
            return <span data-testid="retailer-show-record-not-found">Record Not Found</span>;


        return (
            <Fade in={true} timeout={500}>
                <Box sx={{ width: '100%' }}>
                    <Card>
                        <CardHeader
                            title={record.name}
                            action={<GroupedButton />}
                        />
                        <Divider />
                        <CardContent>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={4}>
                                    Record ID: <span data-testid="retailer-show-field-record-id">{record.recordId}</span>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    Name: {record.name}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    Description: <RichTextField
                                    // name="description"
                                    source="description" />
                                </Grid>
                            </Grid>

                            <Divider />

                            <Grid container>
                                <Grid item xs={12}>
                                    <>Created By {record.createdBy} @ {new Date(record.createdDate).toLocaleString()}</>
                                </Grid>
                                <Grid item xs={12}>
                                    <>Last Updated
                                        By {record.updatedBy} @ {new Date(record.updatedDate).toLocaleString()}</>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>

                    <Card sx={{ mt: 5 }}>
                        <CardHeader title="Membership" />
                        <Divider />
                        <CardContent>
                            <ReferenceManyField reference="retailer/membership" target="retailerId" pagination={<Pagination />} perPage={5}>
                                <Datagrid bulkActionButtons={false} rowClick={(id, _resource, _record) => {
                                    navigate({
                                        pathname: `/retailer/membership/${id}/show`,
                                        search: `?retailerId=${record.recordId}`
                                    });
                                    return false;
                                }}>

                                    <FunctionField label="common.table.actions"
                                                   render={(record: RetailerMembership) => (
                                                       <Stack component="div" spacing={1} direction="row">
                                                           <CustomShowButton customPath={`/retailer/membership/${record.recordId}/show?retailerId=${record.retailerId}`} />
                                                           <CustomEditButton customPath={`/retailer/membership/${record.recordId}/edit?retailerId=${record.retailerId}`}/>
                                                           <CustomCloneButton
                                                               omitProps={[
                                                                   "id", "recordId", "createdBy", "createdDate", "updatedBy", "updatedDate",
                                                               ]}
                                                           />
                                                           <CustomDeleteIconButtonV2 IconButtonProps={{
                                                               sx: {
                                                                   "svg": {
                                                                       fontSize: "16px",
                                                                   },
                                                               },
                                                           }} />
                                                       </Stack>
                                                   )} />
                                    <NumberField source="recordId" />
                                    <TextField source="name" />
                                    <TextField source="description" />
                                </Datagrid>
                            </ReferenceManyField>
                        </CardContent>
                    </Card>
                </Box>
            </Fade>
        );
    };

    return (
        <Show actions={false}
              component="div"
              title="Retailer Management"
              resource="retailer"
              id={id}
        >
            <Box component="div">
                <CustomBreadcrumbs title="View Retailer" items={breadcrumbItems} />
                <RetailerShowContent />
            </Box>
        </Show>
    );

};

export default RetailerShow;
