import axios from "axios";
import config from "../../../config.json";
import { getAuth } from "../../../auth/AuthUtils";

const getFolderDetails = async (projectUuid: string, userUuid: string, folderId?: string): Promise<FolderDetails> => {
    return axios.get<FolderDetails>(`${config.API_BASE_URL}/project/${projectUuid}/user/${userUuid}/document/folder/${!!folderId ? folderId : 0}/details`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getAuth()}`
        }
    }).then(result => result.data);
};

export default getFolderDetails;
