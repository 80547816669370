import { DataProvider } from "react-admin";
import { axiosRequest, convertToGetListParams } from "./DataProviderUtils";
import config from "../config.json";
import { getUserUuid } from "../auth/AuthUtils";

export const voucherSubResourceDataProvider = (projectUuid: string, subResourceName: string): DataProvider => {
    const baseUrl = config.API_BASE_URL;

    const userUuid = getUserUuid();

    const getBaseUri = () => {
        switch (subResourceName) {
            case "remark":
            case "consumption":
                return `${baseUrl}/project/${projectUuid}/user/${userUuid}/voucher/${subResourceName}`;
            default:
                throw new Error("Unknown sub resource?!");
        }
    };

    return {
        create: async (_resource, params) => {
            return axiosRequest({
                fetchType: "CREATE",
                url: `${getBaseUri()}/create`,
                body: JSON.stringify(params.data),
            });
        },
        delete: async (_resource, params) => {
            return axiosRequest({
                fetchType: "DELETE",
                url: `${getBaseUri()}/${params.id}/delete`,
            });
        },
        deleteMany: async () => Promise.reject("Method Not Supported"),
        getList: async (_resource, params) => {
            return axiosRequest({
                fetchType: "GET_LIST",
                url: `${getBaseUri()}/list${convertToGetListParams(params)}`,
            });
        },
        getMany: async () => Promise.reject("Method Not Supported"),
        getManyReference: async (_resource, params) => {
            const { id, target } = params;
            return axiosRequest({
                fetchType: "GET_MANY_REFERENCE",
                url: `${getBaseUri()}/list${convertToGetListParams(params)}&${target}=${id}`,
            });
        },
        getOne: async (_resource, params) => {
            return axiosRequest({
                fetchType: "GET_ONE",
                url: `${getBaseUri()}/${params.id}`,
            });
        },
        update: async (_resource, params) => {
            const { id, data } = { ...params };
            return axiosRequest({
                fetchType: "UPDATE",
                url: `${getBaseUri()}/${id}/update`,
                body: JSON.stringify(data),
            });
        },
        updateMany: () => Promise.reject("Method Not Supported"),
    } as DataProvider;
};
