import React, { useMemo } from "react";
import { Create } from "react-admin";
import { useNavigate } from "react-router";
import CustomBreadcrumbs, { BreadcrumbItem } from "../../../component/CustomBreadcrumbs";
import RetailerMembershipEditForm from "./form/RetailerMembershipEditForm";
import { useSearchParams } from "react-router-dom";

const RetailerCreate: React.FC = () => {

    const navigate = useNavigate();
    const [ urlParams ] = useSearchParams();

    const retailerId = useMemo(() => {
        return urlParams.get("retailerId");
    }, [ urlParams ]);

    const breadcrumbItems: BreadcrumbItem[] = [
        {
            label: 'Retailers',
            resource: 'retailer',
            type: 'list',
        },
        {
            label: 'Create',
            isCurrent: true,
        },
    ];

    return (
        <Create actions={false}
                component="div"
                title="Retailer Management"
                resource="retailer/membership"
                redirect={false}
                mutationOptions={{
                    onSuccess: (data: Retailer, _variables, _context) => {
                        // replace path -> if user go back previous page -> will go to list / the second previous instead of form page
                        navigate(`/retailer/membership/${data.recordId}/show`, {
                            replace: true,
                        });
                    }
                }}
        >
            <CustomBreadcrumbs title="Create Retailer Membership" items={breadcrumbItems} />
            <RetailerMembershipEditForm type="create" />
        </Create>
    );

};

export default RetailerCreate;
