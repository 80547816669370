import { getUserUuid } from "../../../auth/AuthUtils";
import { useDataProvider, useStore } from "react-admin";
import { VoucherDataProvider } from "./voucherDataProvider";
import { useQuery } from "@tanstack/react-query";

const useVoucherConsumptionDetails = (form: VoucherConsumptionDetailsSearchForm) => {
    const [ projectUuid ] = useStore<string>("selected-project");

    const userUuid = getUserUuid() || "";
    const dataProvider = useDataProvider<VoucherDataProvider>();

    const {
        isError,
        error,
        data,
        isLoading,
        isFetching,
        refetch,
    } = useQuery(
        {
            queryKey: [ 'voucherConsumptionDetails', projectUuid, userUuid, form ],
            queryFn: () => dataProvider.fetchVoucherConsumptionDetails("voucher", {
                projectUuid,
                userUuid,
                form,
            }),
            enabled: ! (projectUuid === "" && userUuid === ""),
            retry: false,
        }
    );

    return { isError, error, data, isLoading, isFetching, refetch };
};

export {
    useVoucherConsumptionDetails,
};
