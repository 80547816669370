import { getUserUuid } from "../../../auth/AuthUtils";
import { useQuery } from "@tanstack/react-query";
import getFileDetails from "./getFileDetails";
import { useStore } from "react-admin";

const useGetFileDetails = (fileId?: string) => {
    const [ projectUuid ] = useStore<string>("selected-project");
    const userUuid = getUserUuid() || "";

    return useQuery({
        queryKey: [ 'getFile', projectUuid, fileId ],
        queryFn: () => getFileDetails(projectUuid, userUuid, fileId || ""),
        enabled: projectUuid.length > 0 && userUuid.length > 0 && !! fileId,
    });
};

export default useGetFileDetails;
