import {
    CREATE, DELETE,
    GET_LIST,
    GET_MANY,
    GET_MANY_REFERENCE,
    GetListParams, HttpError,
    PaginationPayload, RaRecord,
    SortPayload,
} from "react-admin";
import axios, { AxiosError, Method } from "axios";

const convertHTTPResponse = (response: any, type: string, targetIdField: string) => {
    const { data } = response;
    switch (type) {
        case GET_MANY_REFERENCE:
        case GET_LIST:
            if (! data.hasOwnProperty("totalElements")) {
                throw new Error(
                    "The numberOfElements property must be must be present in the Json response",
                );
            }
            return {
                data: data.content.map((resource: any) => ({
                    ...resource,
                    id: resource[targetIdField] ? resource[targetIdField] : resource.id,
                })),
                total: parseInt(data.totalElements, 10),
            };
        case CREATE:
            return { data: { ...data, id: data[targetIdField] ? data[targetIdField] : data.id } };
        case GET_MANY:
            return {
                data: data.content.map((resource: any) => ({
                    ...resource,
                    id: resource[targetIdField] ? resource[targetIdField] : resource.id,
                })),
            };
        case DELETE:
            return {
                data: { ...data },
            };
        default:
            return { data: { ...data, id: data[targetIdField] ? data[targetIdField] : data?.id } };
    }
};

const convertToGetListParams = (params: GetListParams) => {
    const { filter, sort, pagination } = params;
    return `${convertToPageAndSize(pagination)}${convertToSort(sort)}${convertToFilter(filter)}`;
};

const convertToPageAndSize = (pagination: PaginationPayload | undefined) => {
    if (! pagination)
        return "";

    return `?&page=${pagination.page}&size=${pagination.perPage}`;
};

const convertToSort = (sort: SortPayload | undefined) => {
    if (! sort)
        return "";

    return `&sort=${sort.field === "id" ? "recordId" : sort.field},${sort.order}`;
};

const convertToFilter = (filterObject: any) => {
    if (! filterObject)
        return "";

    return Object.entries(filterObject)
        .reduce((p, [ key, value ]) => {
            // @ts-ignore
            return `${p}&${key}=${encodeURIComponent(value)}`;
        }, "");
};

const DEFAULT_HEADERS = {
    Authorization: `Bearer ${localStorage.getItem("auth")}`,
    "Content-Type": "application/json",
};

type BasicRequest<RecordType extends RaRecord = any> = {
    url: string;
    // Optional field, if no value presented, it should be "recordId"
    targetIdField?: "recordId" | string;
    // Optional field, if no value present, it will be followed with fetchType
    method?: Method;
};

type CreateRequest<RecordType extends RaRecord = any> = BasicRequest<RecordType> & {
    fetchType: "CREATE";
    body: string;
};

type DeleteRequest<RecordType extends RaRecord = any> = BasicRequest<RecordType> & {
    fetchType: "DELETE";
};

type DeleteManyRequest<RecordType extends RaRecord = any> = BasicRequest<RecordType> & {
    fetchType: "DELETE_MANY";
};

type GetListRequest<RecordType extends RaRecord = any> = BasicRequest<RecordType> & {
    fetchType: "GET_LIST";
};

type GetManyRequest<RecordType extends RaRecord = any> = BasicRequest<RecordType> & {
    fetchType: "GET_MANY";
};

type getManyReferenceRequest<RecordType extends RaRecord = any> = BasicRequest<RecordType> & {
    fetchType: "GET_MANY_REFERENCE";
};

type GetOneRequest<RecordType extends RaRecord = any> = BasicRequest<RecordType> & {
    fetchType: "GET_ONE";
};

type UpdateRequest<RecordType extends RaRecord = any> = BasicRequest<RecordType> & {
    fetchType: "UPDATE";
    body: string;
};

type UpdateManyRequest<RecordType extends RaRecord = any> = BasicRequest<RecordType> & {
    fetchType: "UPDATE_MANY";
}

type GenericRequest<RecordType extends RaRecord = any> =
    CreateRequest<RecordType> |
    DeleteRequest<RecordType> |
    DeleteManyRequest<RecordType> |
    GetListRequest<RecordType> |
    GetManyRequest<RecordType> |
    getManyReferenceRequest<RecordType> |
    GetOneRequest<RecordType> |
    UpdateRequest<RecordType> |
    UpdateManyRequest<RecordType>
    ;

const axiosRequest = (request: GenericRequest) => {
    const { url, fetchType, targetIdField = "recordId", ...rest } = request;

    switch (fetchType) {
        case "CREATE": {
            const { method = "POST", body } = rest as CreateRequest;
            return axiosRequests(fetchType, url, method, targetIdField, body);
        }
        case "UPDATE": {
            const { method = "PUT", body } = rest as UpdateRequest;
            return axiosRequests(fetchType, url, method, targetIdField, body);
        }
        case "DELETE": {
            const { method = "DELETE" } = rest;
            return axiosRequests(fetchType, url, method, targetIdField);
        }
        case "GET_ONE":
        case "GET_LIST":
        case "GET_MANY":
        case "GET_MANY_REFERENCE": {
            const { method = "GET" } = rest;
            return axiosRequests(fetchType, url, method, targetIdField);
        }
        case "DELETE_MANY":
        case "UPDATE_MANY":
        default: {
            throw new Error(`Unsupported request ${url}`);
        }
    }
};


const axiosRequests = async (fetchType: string, url: string, method: Method, targetId: string = "recordId", body: string | null = null) => {
    return await axios
        .request({
            url,
            headers: DEFAULT_HEADERS,
            method: method,
            data: body,
        })
        .catch((axiosError: AxiosError<any>) => {
            const { response } = axiosError;
            if (response) {
                const { status, statusText, data } = response;
                if (status < 200 || status >= 300) {
                    let returnBody: any = data;
                    if (data.hasOwnProperty("errors")) {
                        returnBody = {
                            "errors": Object.keys(data.errors).reduce((prev: { [p: string]: any }, curr) => {
                                // prev
                                const errors: string[] = data.errors[curr];
                                prev[curr] = errors.join(", ");
                                return prev;
                            }, {
                                root: { serverError: "Some of the provided values are not valid. Please fix them and retry." },
                            }),
                        };
                    }

                    return Promise.reject(
                        new HttpError(
                            (data && data.message) || statusText,
                            status,
                            returnBody,
                        ),
                    );
                }
                return Promise.reject(
                    new HttpError(
                        "Unknown error",
                        status,
                    ),
                );
            }
        })
        .then(
            response => convertHTTPResponse(response, fetchType, targetId),
        );

};

export {
    convertHTTPResponse,
    convertToGetListParams,
    convertToPageAndSize,
    convertToSort,
    convertToFilter,
    axiosRequest,
};
