import React, { Fragment } from "react";
import { Paper, SxProps } from "@mui/material";
import { Title, useRedirect } from "react-admin";
import Grid from "@mui/material/Unstable_Grid2";

const VoucherIndex: React.FC = () => {

    const redirect = useRedirect();

    const paperSx: SxProps = {
        minHeight: "150px",
        height: "100%",
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
        "&:hover": {
            cursor: "pointer",
            backgroundColor: "rgba(18, 18, 18, 0.5)",
        },
        fontSize: "24px",
        fontWeight: "bolder",
    };

    const LinkBox: React.FC<{ title: string, redirectTo: string }> = ({ title, redirectTo }) => {
        return (
            <Grid xs={12} md={3}>
                <Paper
                    component="div"
                    sx={paperSx}
                    onClick={() => {
                        redirect(redirectTo);
                    }}
                >
                    {title}
                </Paper>
            </Grid>
        );
    };

    return (
        <Fragment>
            <Title title="resources.voucher.page.index.title" />

            <Grid container m={2} spacing={2} sx={{ textAlign: "center" }}>
                <LinkBox title="View by retailers" redirectTo="/voucher/selectByRetailer" />
                <LinkBox title="List All Vouchers"
                         redirectTo={`/voucher/list?filter=${encodeURIComponent(JSON.stringify({}))}`} />
                <LinkBox title="Create Vouchers" redirectTo="/voucher/create" />
                <LinkBox title="Statistics" redirectTo="/voucher/statistics" />
            </Grid>
        </Fragment>
    );
};

export default VoucherIndex;
