import { Form, required, SaveHandler, TextInput, useNotify, useTranslate } from "react-admin";
import {
    Alert,
    Avatar,
    Button,
    Card,
    CardActions,
    CardContent,
    CircularProgress, ClickAwayListener,
    Divider, Fab,
    Stack, Zoom,
} from "@mui/material";
import Box from "@mui/material/Box";
import LockIcon from "@mui/icons-material/Lock";
import * as React from "react";
import { Fragment, useCallback, useState } from "react";
import { useFormContext } from "react-hook-form";
import { userLogin } from "./dataHookTest";
import { setUserUuid } from "../../support/googleAnalytics";
import { AxiosError } from "axios";
import { useNavigate } from "react-router";
import TranslateIcon from "@mui/icons-material/Translate";
import { useLocales, useLocaleState } from "ra-core";

export const LOGIN_PAGE_TEST_ID = {
    FORM: "login-page-form",
    INPUTS: {
        USERNAME: "login-page-username-input",
        PASSWORD: "login-page-password-input",
    },
    BUTTONS: {
        SUBMIT: "login-page-submit-button",
    },
    LOADING: {
        CP: "login-page-loading-cp",
    }
};

const Login = () => {

    const [ loading, setLoading ] = useState(false);
    const translate = useTranslate();
    const navigate = useNavigate();

    const notify = useNotify();

    const onSubmitHandler = (data: any) => {
        setLoading(true);
        userLogin(
            data.username,
            data.password,
        )
            .then(response => {
                return response.data;
            })
            .then((auth) => {
                navigate("/");
                localStorage.setItem("auth", auth.token);
                setUserUuid();
            })
            .catch((error: AxiosError<ErrorDTO>) => {
                console.log("Error: ", error);
                if (error && error.response) {
                    notify(error.response.data.message, { type: "error" });
                } else {
                    notify(error.message, { type: "error" });
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const ButtonTest = () => {
        const { formState: { errors } } = useFormContext();

        return (
            <Button
                variant="contained"
                type="submit"
                color="primary"
                disabled={loading || !! errors.username || !! errors.password}
                fullWidth
                data-testid={LOGIN_PAGE_TEST_ID.BUTTONS.SUBMIT}
            >
                {loading && (
                    <CircularProgress size={25} thickness={2} data-testid={LOGIN_PAGE_TEST_ID.LOADING.CP} />
                )}
                {translate("ra.auth.sign_in")}
            </Button>
        );
    };

    const FloatingLocaleButtonGroup: React.FC = () => {
        const [ expended, setExpended ] = useState(false);
        const translate = useTranslate();
        const languages = useLocales();
        const [ locale, setLocale ] = useLocaleState();

        const onClickAway = useCallback(() => {
            if (expended)
                setExpended(false);
        }, [ expended ]);

        return (
            <ClickAwayListener onClickAway={onClickAway}>
                <Box component="div"
                     sx={{
                         zIndex: 222,
                         position: "fixed",
                         bottom: 16,
                         right: 16,
                     }}
                >
                    <Stack
                        direction="column-reverse"
                        spacing={2}
                        alignItems="flex-end"
                        onClick={onClickAway}
                    >
                        <Fab
                            onClick={() => {
                                setExpended(true);
                            }}
                            color="warning"
                            data-testid="login-page-locale-menu-toggle-button"
                        >
                            <TranslateIcon />
                        </Fab>
                        <Zoom in={expended}>
                            <Fab
                                color={locale === "en-gb" ? "primary" : "secondary"}
                                variant="extended"
                                onClick={() => setLocale("en-gb")}
                                data-testid="login-page-locale-en-gb-button"
                            >
                                {translate("common.locale.en")}
                            </Fab>
                        </Zoom>
                        <Zoom in={expended}>
                            <Fab
                                color={locale === "zh-hk" ? "primary" : "secondary"}
                                variant="extended"
                                onClick={() => setLocale("zh-hk")}
                                data-testid="login-page-locale-zh-hk-button"
                            >
                                {translate("common.locale.zh_HK")}
                            </Fab>
                        </Zoom>
                        {languages.map(language => (
                            <Zoom in={expended} key={language.locale}>
                                <Fab
                                    color={locale === language.locale ? "primary" : "secondary"}
                                    variant="extended"
                                    onClick={() => setLocale(language.locale)}
                                    data-testid={`login-page-locale-v-${language.locale}-button`}
                                >
                                    {language.name}
                                </Fab>
                            </Zoom>
                        ))}
                    </Stack>
                </Box>
            </ClickAwayListener>
        );
    };

    return (
        <Fragment>
            <Form onSubmit={onSubmitHandler} mode="onBlur" reValidateMode="onBlur">
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        minHeight: "100vh",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                    }}
                    data-testid={LOGIN_PAGE_TEST_ID.FORM}
                >
                    <Card sx={{ minWidth: 300, marginTop: "6em" }}>
                        <CardContent>
                            <Alert severity="warning" color="warning">
                                Credentials might be changed, please contact system admin.
                            </Alert>
                        </CardContent>
                        <CardContent>
                            <Box
                                sx={{
                                    margin: "1em",
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <Avatar sx={{ bgcolor: "secondary.main" }}>
                                    <LockIcon />
                                </Avatar>
                            </Box>
                            <Box sx={{ padding: "0 1em 1em 1em" }}>
                                <Box sx={{ marginTop: "1em" }}>
                                    <TextInput
                                        label={translate("ra.auth.username")}
                                        name="username"
                                        source="username"
                                        type="text"
                                        fullWidth
                                        validate={required()}
                                        inputProps={{
                                            "data-testid": LOGIN_PAGE_TEST_ID.INPUTS.USERNAME,
                                        }}
                                    />
                                </Box>
                                <Box sx={{ marginTop: "1em" }}>
                                    <TextInput
                                        label={translate("ra.auth.password")}
                                        name="password"
                                        source="password"
                                        type="password"
                                        fullWidth
                                        validate={required()}
                                        inputProps={{
                                            "data-testid": LOGIN_PAGE_TEST_ID.INPUTS.PASSWORD,
                                        }}
                                    />
                                </Box>
                            </Box>
                        </CardContent>
                        <Divider />
                        <CardActions>
                            <ButtonTest />
                        </CardActions>
                    </Card>
                </Box>
            </Form>
            <FloatingLocaleButtonGroup />
        </Fragment>
    );

};

export default Login;
