import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { AutocompleteInput, ReferenceInput, required, SelectInput, SimpleForm, TextInput } from "react-admin";
import { Card, CardContent, Dialog, Grid, IconButton, InputAdornment } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { BrowserMultiFormatReader, Result } from "@zxing/library";
import { convertToFormatName } from "../../../../helper/BarcodeTypeUtils";
import NewScanner from "../../../scanner/NewScanner";
import AddAPhotoOutlinedIcon from "@mui/icons-material/AddAPhotoOutlined";
import { BARCODE_FORMAT_TYPE } from "../../../../constant/BarcodeFormatType";
import { useSearchParams } from "react-router-dom";

const RetailerMembershipEditForm: React.FC<EditFormProps> = ({ type }) => {

    const FormContent = () => {
        // Need to be nested inside SimpleForm element
        const [ urlParams ] = useSearchParams();
        const { setValue } = useFormContext();

        const retailerId = useMemo(() => {
            return urlParams.get("retailerId");
        }, [urlParams]);

        useEffect(()=> {
            if (!!retailerId && type === "create")
                setValue("retailerId", retailerId);
        }, [retailerId, setValue]);

        return (
            <Card sx={{ width: "100%" }}>
                <CardContent sx={{ width: "100%" }}>
                    <Grid container spacing={{ xs: 0, sm: 0, md: 2 }}>
                        {type === "edit" && (
                            <Grid item xs={12} sm={12} md={4}>
                                <TextInput name="recordId"
                                           source="recordId"
                                           label="Record ID"
                                           fullWidth
                                           variant="outlined"
                                           disabled
                                />
                            </Grid>
                        )}
                        <BarcodeReadingInputGrid />
                        <Grid item xs={12} sm={12} md={4}>
                            <TextInput name="name"
                                       source="name"
                                       label="Name"
                                       fullWidth
                                       variant="outlined"
                                       validate={[ required() ]}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12}>
                            <TextInput name="description"
                                       source="description"
                                       label="Description"
                                       fullWidth
                                       variant="outlined"
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        );
    };

    const BarcodeReadingInputGrid: React.FC = () => {

        const [ dialogOpen, setDialogOpen ] = useState(false);
        const codeReader = useRef(new BrowserMultiFormatReader());
        const { setValue } = useFormContext();

        useEffect(() => {
            if (! dialogOpen) {
                codeReader.current.reset();
            }
        }, [ dialogOpen ]);

        const dialogOpenHandler = () => {
            setDialogOpen(true);
        }

        const ScannerDialog = () => {

            const resultHandler = (result: Result) => {
                setValue("barcodeText", result.getText(), { shouldTouch: true, shouldDirty: true, });
                setValue("barcodeType", convertToFormatName(result.getBarcodeFormat()), {
                    shouldTouch: true,
                    shouldDirty: true,
                });
                setDialogOpen(false);
            }

            const dialogCloseHandler = () => {
                setDialogOpen(false);
                codeReader.current.reset();
            }

            return (
                <>
                    {dialogOpen && (
                        <Dialog open={dialogOpen}
                                onClose={dialogCloseHandler}
                        >
                            <NewScanner resultHandler={resultHandler}
                                        codeReader={codeReader}
                            />
                        </Dialog>
                    )}
                </>
            );
        };

        return (
            <Fragment>

                <Grid item xs={12} sm={12} md={4}>
                    <ReferenceInput name="retailerIdInput"
                                    source="retailerId"
                                    reference="retailer"
                                    filter={{
                                        isVoucherRetailer: true
                                    }}
                                    fullWidth
                    >
                        <AutocompleteInput name="retailerId"
                                           optionText="name"
                                           optionValue="recordId"
                                           filterSelectedOptions={false}
                                           TextFieldProps={{
                                               InputLabelProps: {
                                                   shrink: true,
                                               },
                                           }}
                                           validate={[ required() ]}
                        />
                    </ReferenceInput>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                    <TextInput
                        name="barcodeText"
                        source="barcodeText"
                        label="resources.voucher.common.barcodeText"
                        fullWidth
                        inputProps={{
                            maxLength: 255
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton edge="end" onClick={() => dialogOpenHandler()}>
                                        <AddAPhotoOutlinedIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        validate={[ required() ]}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                    <SelectInput
                        name="barcodeType"
                        source="barcodeType"
                        label="resources.voucher.common.barcodeType"
                        choices={BARCODE_FORMAT_TYPE}
                        fullWidth
                        validate={[ required() ]}
                    />
                </Grid>
                <ScannerDialog />
            </Fragment>
        );
    };

    return (
        <SimpleForm mode="onBlur" reValidateMode="onBlur">
            <FormContent />
        </SimpleForm>
    );

};
export default RetailerMembershipEditForm;
