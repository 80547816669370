import React, { JSX, MouseEvent, useEffect, useState } from "react";
import {
    AppBar,
    AppBarProps,
    Layout,
    LayoutProps,
    useUserMenu,
    UserMenu,
    Logout,
    LoadingIndicator, ToggleThemeButton, useStore,
} from "react-admin";

import { MyMenu } from "./MyMenu";
import SettingsIcon from "@mui/icons-material/Settings";
import KeyIcon from "@mui/icons-material/Key";
import { Menu, MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import { useNavigate } from "react-router";
import { parseJwt } from "./auth/AuthUtils";
import TranslateIcon from "@mui/icons-material/Translate";
import { useLocales, useLocaleState } from "ra-core";

export const MyLayout = (props: JSX.IntrinsicAttributes & LayoutProps) => {

    // only available on with layout only.
    // If the custom Routes is no layout - this validation logic will be failed.
    const navigate = useNavigate();

    const [ selectedProject, setSelectedProject ] = useStore<string>("selected-project", "");

    useEffect(() => {
        if (!selectedProject) {
            const jwtPayload = parseJwt();
            const projects = jwtPayload.projects ? Object.keys(jwtPayload.projects) : [];

            if (projects.length === 0) {
                console.log("No Project Assigned!");
            } else if (projects.length === 1) {
                console.log("only one project! Auto assign that project to localStorage");
                setSelectedProject(projects[0]);
            } else {
                if (projects.length > 1) {
                    console.log("More than one project was found!");
                    navigate("/projectSelect");
                }
            }
        }
    }, [navigate, selectedProject, setSelectedProject]);

    return (<Layout {...props} menu={MyMenu} appBar={MyAppBar} sx={{
        "& .MuiDrawer-root": {
            marginTop: "0 !important",
        },
        "& .RaLayout-appFrame": {
            marginTop: "48px",
        },
        "& .RaLayout-content": {
            px: {
                xs: 0,
                md: 1,
            },
        },
    }} />);
};

export const MyAppBar = (props: JSX.IntrinsicAttributes & AppBarProps) => (
    <AppBar container="div"
            userMenu={<MyUserMenu />}
            toolbar={<><LoadingIndicator /><ToggleThemeButton /></>} />
);


const ProjectSwitchButton = React.forwardRef((props, ref) => {
    const navigate = useNavigate();
    const userMenu = useUserMenu();
    const jwtPayload: any = parseJwt();
    if (jwtPayload.projects.length <= 1) return null;

    const onClick = () => {
        if (userMenu)
            userMenu.onClose();
        navigate("/projectSelect");
    };

    return (
        <MenuItem onClick={onClick} {...props}>
            <ListItemIcon>
                <SettingsIcon />
            </ListItemIcon>
            <ListItemText>Switch project</ListItemText>
        </MenuItem>
    );
});


const PasswordChangeButton = React.forwardRef((props, ref) => {
    const navigate = useNavigate();
    const userMenu = useUserMenu();

    const onClick = () => {
        if (userMenu)
            userMenu.onClose();
        navigate("/password/change");
    };

    return (
        <MenuItem onClick={onClick} {...props}>
            <ListItemIcon>
                <KeyIcon />
            </ListItemIcon>
            <ListItemText>Change Password</ListItemText>
        </MenuItem>
    );
});

const LocaleChangeMenu = () => {
    const locales = useLocales();
    const [ anchorEl, setAnchorEl ] = useState<null | HTMLElement>(null);
    const languages = useLocales();
    const [ locale, setLocale ] = useLocaleState();

    if (locales.length === 0) {
        return null;
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const changeLocale = (locale: string) => (): void => {
        setLocale(locale);
        setAnchorEl(null);
    };

    const handleLanguageClick = (event: MouseEvent<HTMLElement>): void => {
        setAnchorEl(event.currentTarget);
    };


    return (
        <>
            <MenuItem onClick={handleLanguageClick}>
                <ListItemIcon>
                    <TranslateIcon />
                </ListItemIcon>
                <ListItemText>Language</ListItemText>
            </MenuItem>
            <Menu id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
            >
                {languages.map(language => (
                    <MenuItem
                        key={language.locale}
                        onClick={changeLocale(language.locale)}
                        selected={language.locale === locale}
                    >
                        {language.name}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};

export const ReloadMenuButton = () => (
    <MenuItem onClick={() => window.location.reload()}>
        <ListItemIcon>
            <TranslateIcon />
        </ListItemIcon>
        <ListItemText>Reload</ListItemText>
    </MenuItem>
);

export const MyUserMenu = () => (
    <UserMenu>
        <ProjectSwitchButton data-testid="project-switch-button" />
        <PasswordChangeButton />
        <LocaleChangeMenu />
        <ReloadMenuButton />
        <Logout />
    </UserMenu>
);