import { useStore } from "react-admin";

export const parseJwt = (token: string | null = getAuth()): JwtPayload => {
    if (!token) throw new Error("No token was provided");
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload) as JwtPayload;
}

export const useSelectedProject = () => {
    const [ projectUuid ] = useStore<string>("selected-project");
    if (!!projectUuid) {
        const projectFeatures = getProjects()[projectUuid];
        if (projectFeatures)
            return {
                uuid: projectUuid,
                features: projectFeatures,
            };
    }
    return null;
}

export const getSelectedProject = (): Project | null => {
    const selectedProjectId = localStorage.getItem('project');
    if (selectedProjectId) {
        const projectFeatures = getProjects()[selectedProjectId];
        if (projectFeatures)
            return {
                uuid: selectedProjectId,
                features: projectFeatures,
            };
    }
    return null;
}

export const getProjectUuid = (): string => {
    const projectUuid = localStorage.getItem('project');
    if (!!projectUuid) return projectUuid;
    throw new Error("Invalid project ID");
}

export const getUserUuid = (): string => {
    const jwtPayload = parseJwt();
    if (!!jwtPayload && !!jwtPayload.uuid) return jwtPayload.uuid;
    throw new Error("Invalid user ID");
};

export const getAuth = (): string | null => {
    return localStorage.getItem("auth");
}

export const getUserFeatures = (): string[] => {
    const jwtPayload = parseJwt();
    if (! jwtPayload || !Array.isArray(jwtPayload.features)) return [];
    return jwtPayload.features;
}

export const getSelectedProjectFeatures = (): string[] => {
    const selectedProject = getSelectedProject();
    if (selectedProject) {
        return selectedProject.features;
    }
    return [];
}

export const getProjects = () => {
    const jwtPayload = parseJwt();
    if (! jwtPayload) return {};
    return jwtPayload.projects;
}