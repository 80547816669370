import { CreateFolderOptions, CreateFolderRequest, UploadFileOptions, UploadFileRequest } from "./types";
import { useMutation, UseMutationResult } from "@tanstack/react-query";
import createFolder from "./createFolder";
import { getUserUuid } from "../../../auth/AuthUtils";
import uploadFile from "./uploadFile";
import { useStore } from "react-admin";

export const useCreateFolder = (options?: CreateFolderOptions): {
    update: (request: CreateFolderRequest) => void;
    mutation: UseMutationResult<FileV2, unknown, CreateFolderRequest>;
} => {
    const [ projectUuid ] = useStore<string>("selected-project");
    const mutation = useMutation<FileV2, unknown, CreateFolderRequest>({
        mutationFn: (request) => {
            return createFolder(projectUuid, getUserUuid(), request);
        },
        onSuccess: (data, variables, context) => {
            if (!! options && !! options.onSuccess) {
                options.onSuccess(data, variables, context);
            }
        },
        onError: (data, variables, context) => {
            if (!! options && !! options.onError) {
                options.onError(data, variables, context);
            }
        },
        onSettled: (data, error, variables, context) => {
            if (!! options && !! options.onSettled) {
                options.onSettled(data, error, variables, context);
            }
        },
    });

    const update = (request: CreateFolderRequest) => {
        return mutation.mutate(request);
    };

    return { update, mutation };
};

export const useUploadFile = (options?: UploadFileOptions) => {
    const [ projectUuid ] = useStore<string>("selected-project");
    const mutation = useMutation<unknown, unknown, UploadFileRequest>({
        mutationFn: (request) => {
            const { file, forceUpload = false } = request;
            return uploadFile(projectUuid, getUserUuid(), file.name, file, forceUpload);
        },
        onSuccess: (data, variables, context) => {
            if (!! options && !! options.onSuccess) {
                options.onSuccess(data, variables, context);
            }
        },
        onError: (data, variables, context) => {
            if (!! options && !! options.onError) {
                options.onError(data, variables, context);
            }
        },
        onSettled: (data, error, variables, context) => {
            if (!! options && !! options.onSettled) {
                options.onSettled(data, error, variables, context);
            }
        },
    });

    const update = (request: UploadFileRequest) => {
        return mutation.mutate(request);
    };

    return { update, mutation };
}

