import { DataProvider } from "react-admin";
import { axiosRequest, convertToGetListParams } from "../../hooks/DataProviderUtils";
import { getUserUuid } from "../../auth/AuthUtils";

export const sharedSuperAdminDataProvider = (baseUrl: string, targetIdField?: string): DataProvider => {

    return {
        create: (resource, params) => {
            return axiosRequest({
                fetchType: "CREATE",
                url: `${baseUrl}/user/${getUserUuid()}/${resource}/create`,
                body: JSON.stringify(params.data),
                targetIdField,
            });
        },
        delete: (resource, params) => {
            return axiosRequest({
                fetchType: "DELETE",
                url: `${baseUrl}/user/${getUserUuid()}/${resource}/${params.id}/delete`,
                targetIdField,
            });
        },
        deleteMany: (_resource, _params) => Promise.reject("Delete many is not supported"),
        getList: (resource, params) => {
            return axiosRequest({
                fetchType: "GET_LIST",
                url: `${baseUrl}/user/${getUserUuid()}/${resource}/list${convertToGetListParams(params)}`,
                targetIdField,
            });
        },
        getMany: (resource, params) => {
            const { ids } = params;

            const mixedParam = ids.reduce((p, id) => {
                return `${p}&recordId=${id}`;
            }, "");
            return axiosRequest({
                fetchType: "GET_MANY",
                url: `${baseUrl}/user/${getUserUuid()}/${resource}?${mixedParam}&page=0&size=9999`,
                targetIdField,
            });
        },
        getManyReference: (resource, params) => {
            const { id, target } = params;
            return axiosRequest({
                fetchType: "GET_MANY_REFERENCE",
                url: `${baseUrl}/user/${getUserUuid()}/${resource}/list${convertToGetListParams(params)}&${target}=${id}`,
                targetIdField,
            });
        },
        getOne: (resource, params) => {
            const { id } = params;
            return axiosRequest({
                fetchType: "GET_ONE",
                url: `${baseUrl}/user/${getUserUuid()}/${resource}/${id}`,
                targetIdField,
            });
        },
        update: (resource, params) => {
            const { id, data } = params;
            return axiosRequest({
                fetchType: "UPDATE",
                url: `${baseUrl}/user/${getUserUuid()}/${resource}/${id}/update`,
                body: JSON.stringify(data),
                targetIdField,
            });
        },
        updateMany: (_resource, _params) => Promise.reject("Not supported"),
    };
};
